<template>
  <BasicModal>
    <FormCheckbox :is-toggle="true" formName="styleGuideForm" fieldName="enablePartyMode" />
    <p class="text-2xl font-bold text-primary-500 party-mode" v-if="isPartyModeEnabled">{{ someOtherValue }}</p>
  </BasicModal>
</template>

<script>

  import BasicModal from '@/components/modals/_BasicModal.vue'
  import FormCheckbox from '@/components/forms/FormCheckbox.vue'

  export default {
    components: {
      BasicModal,
      FormCheckbox,
    },
    props: {
      someOtherValue: {
        type: String,
        default: 'Hello World',
      },
    },
    computed: {
      isPartyModeEnabled() {
        return this.$store.state.forms.styleGuideForm.fields.enablePartyMode.value
      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
